import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
} from "@angular-material-components/datetime-picker";
import { A11yModule } from "@angular/cdk/a11y";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { PortalModule } from "@angular/cdk/portal";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { CdkStepperModule } from "@angular/cdk/stepper";
import { CdkTableModule } from "@angular/cdk/table";
import { CdkTreeModule } from "@angular/cdk/tree";
import { CommonModule, DatePipe, registerLocaleData } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import localePt from "@angular/common/locales/pt";
import { ErrorHandler, LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatBadgeModule } from "@angular/material/badge";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatNativeDateModule, MatRippleModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSliderModule } from "@angular/material/slider";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTreeModule } from "@angular/material/tree";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import {
  FaIconLibrary,
  FontAwesomeModule,
} from "@fortawesome/angular-fontawesome";
import {
  faFacebook,
  faGooglePlus,
  faInstagramSquare,
  faLinkedin,
  faTwitterSquare,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faCheckSquare, faSquare } from "@fortawesome/free-solid-svg-icons";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";
import { MomentModule } from "angular2-moment";
import { NgApexchartsModule } from "ng-apexcharts";
import { ModalModule } from "ngx-bootstrap/modal";
import { NgxMaskModule } from "ngx-mask";
import { QuillModule } from "ngx-quill";
import { GlobalErrorHandler } from "src/app/_helpers/global-error-handler";
import { loginService } from "src/app/_services/login.service";
import { AbrirTicketComponent } from "./abrir-ticket/abrir-ticket.component";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ArquivoComponent } from "./arquivos/arquivos.component";
import { AtenderTicketsOperacaoVivoComponent } from "./atender-tickets-operacao-vivo/atender-tickets-operacao-vivo.component";
import { AtenderTicketsComponent } from "./atender-tickets/atender-tickets.component";
import { AuditoriaComponent } from "./auditoria/auditoria.component";
import { CadastroUserModule, CadastroUserRoutingModule } from "./autenticacao";
import { BoasVindasComponent } from "./boas-vindas/boas-vindas.component";
import { SeusTicketsComponent } from "./boas-vindas/seus-tickets/seus-tickets.component";
import { TicketsParaAtenderComponent } from "./boas-vindas/tickets-para-atender/tickets-para-atender.component";
import { TodosInsumosTempoComponent } from "./boas-vindas/todos-insumos-tempo/todos-insumos-tempo.component";
import { TodosInsumosComponent } from "./boas-vindas/todos-insumos/todos-insumos.component";
import { TodosTicketsComponent } from "./boas-vindas/todos-tickets/todos-tickets.component";
import { ComissaoContestacaoComponent } from "./comissao-contestacao/comissao-contestacao.component";
import { ComissionamentoComponent } from "./comissionamento/comissionamento.component";
import { ConcluirPedidoComponent } from "./concluir-pedido/concluir-pedido.component";
import { ConsultaJuridicaComponent } from "./consulta-juridica/consulta-juridica.component";
import { ConsultarTicketsComponent } from "./consultar-tickets/consultar-tickets.component";
import { ContatoComponent } from "./contato/contato.component";
import { CriacaoConteudoComponent } from "./criacao-conteudo/criacao-conteudo.component";
import { CriarCategoriaDashboardComponent } from "./dashboards/criar-categoria-dashboard/criar-categoria-dashboard.component";
import { CriarDashBoardComponent } from "./dashboards/criar/criar.component";
import { DashBoardsComponent } from "./dashboards/dashboards.component";
import { EditarDashBoardComponent } from "./dashboards/editar/editar.component";
import { ListarCategoriasDashboardComponent } from "./dashboards/listar-categorias-dashboard/listar-categorias-dashboard.component";
import { ListarDashBoardsComponent } from "./dashboards/visualizar/listagem.component";
import { ErrorDialogComponent } from "./error-dialog/error-dialog.component";
import { EscolhaAgendamentoComponent } from "./escolha-agendamento/escolha-agendamento.component";
import { FaqComponent } from "./faq/faq.component";
import { FormularioMatrizComponent } from "./formulario-matriz/formulario-matriz.component";
import { GestaoUsuarioComponent } from "./gestao-usuarios/gestao-usuario.component";
import { HomeComponent } from "./home";
import { CompetenceInputComponent } from "./insumos/competence-input/competence-input.component";
import { ConsultarInsumosComponent } from "./insumos/consultar-insumos/consultar-insumos.component";
import { CriarComponent } from "./insumos/criar/criar.component";
import { InsumoFieldConfigComponent } from "./insumos/insumo-field-config/insumo-field-config.component";
import { InsumoVisualizerComponent } from "./insumos/insumo-visualizer/insumo-visualizer.component";
import { TodosInsumosVisualizarComponent } from "./insumos/todos-insumos-visualizar/todos-insumos-visualizar.component";
import { InsumosComponent } from "./insumos/insumos.component";
import { InsumosModule } from "./insumos/insumos.module";
import { VisualizarComponent } from "./insumos/visualizar/visualizar.component";
import { VisualizerMeusComponent } from "./insumos/visualizer-meus/visualizer-meus.component";
import { LoadingComponent } from "./loading/loading.component";
import { MenuComponent } from "./menu/menu.component";
import { MeusTicketsComponent } from "./meus-tickets/meus-tickets.component";
import { NoticiasComponent } from "./noticias/noticias.component";
import { OQueFazemosComponent } from "./o-que-fazemos/o-que-fazemos.component";
import { OpcoesAgendamentoComponent } from "./opcoes-agendamento/opcoes-agendamento.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { PedidosOnlineComponent } from "./pedidos-online/pedidos-online.component";
import { PerfilUsuarioComponent } from "./perfil-usuario/perfil-usuario.component";
import { ProjetoEDemandaComponent } from "./projeto-e-demanda/projeto-e-demanda.component";
import { ProrrogacaoRegraComponent } from "./projeto-e-demanda/prorrogacao-regra/prorrogacao-regra.component";
import { QuemSomosComponent } from "./quem-somos/quem-somos.component";
import { RecepcionarPedidosComponent } from "./recepcionar-pedidos/recepcionar-pedidos.component";
import { RegistroComponent } from "./registro/registro.component";
import { RelatoriosComponent } from "./relatorios/relatorios.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { SobreComponent } from "./sobre/sobre.component";
import { SolicitacacoCredenciamentoParceiroComponent } from "./solicitacaco-credenciamento-parceiro/solicitacaco-credenciamento-parceiro.component";
import { SolicitacaoAcessoComponent } from "./solicitacao-de-acesso/solicitacao-de-acesso.component";
import { SolicitacoesInternasComponent } from "./solicitacoes-internas/solicitacoes-internas.component";
import { SuportePortaisSistemasComponent } from "./suporte-portais-sistemas/suporte-portais-sistemas.component";
import { CartonagemComponent } from "./tabelas/cartonagem/cartonagem.component";
import { LayoutImportacaoComponent } from "./tabelas/layout-importacao/layout-importacao.component";
import { LocaisGuardaComponent } from "./tabelas/locais-guarda/locais-guarda.component";
import { OrigemComponent } from "./tabelas/origem/origem.component";
import { TabelasComponent } from "./tabelas/tabelas.component";
import { TriagemComponent } from "./triagem/triagem.component";
import { VisualizarPedidoComponent } from "./visualizar-pedido/visualizar-pedido.component";
import { AlertComponent } from "./_components";
import { ErrorInterceptor, JwtInterceptor } from "./_helpers";
import { LoadingInterceptor } from "./_helpers/loading.interceptor";
import { ApplicationPipesModuleModule } from "./_pipes/application-pipes-module.module";
import { AccountService } from "./_services/account.service";
import { QuestionarioService } from "./_services/questionario.service";

registerLocaleData(localePt);

@NgModule({
  declarations: [
    AlertComponent,
    HomeComponent,
    AppComponent,
    MenuComponent,
    SidebarComponent,
    NoticiasComponent,
    PageNotFoundComponent,
    BoasVindasComponent,
    RegistroComponent,
    PerfilUsuarioComponent,
    CriacaoConteudoComponent,
    ContatoComponent,
    FaqComponent,
    QuemSomosComponent,
    OQueFazemosComponent,
    SobreComponent,
    AbrirTicketComponent,
    TabelasComponent,
    TriagemComponent,
    PedidosOnlineComponent,
    RelatoriosComponent,
    SolicitacacoCredenciamentoParceiroComponent,
    CartonagemComponent,
    OrigemComponent,
    LocaisGuardaComponent,
    LayoutImportacaoComponent,
    RecepcionarPedidosComponent,
    ConcluirPedidoComponent,
    OpcoesAgendamentoComponent,
    EscolhaAgendamentoComponent,
    FormularioMatrizComponent,
    VisualizarPedidoComponent,
    GestaoUsuarioComponent,
    SolicitacaoAcessoComponent,
    ProrrogacaoRegraComponent,
    ProjetoEDemandaComponent,
    ComissaoContestacaoComponent,
    SuportePortaisSistemasComponent,
    ConsultaJuridicaComponent,
    SolicitacoesInternasComponent,
    MeusTicketsComponent,
    ComissionamentoComponent,
    AtenderTicketsComponent,
    AtenderTicketsOperacaoVivoComponent,
    ConsultarTicketsComponent,
    InsumosComponent,
    CriarComponent,
    VisualizarComponent,
    VisualizerMeusComponent,
    InsumoVisualizerComponent,
    TodosInsumosVisualizarComponent,
    ConsultarInsumosComponent,
    InsumoFieldConfigComponent,
    AuditoriaComponent,
    TodosTicketsComponent,
    SeusTicketsComponent,
    TicketsParaAtenderComponent,
    LoadingComponent,
    TodosInsumosComponent,
    TodosInsumosTempoComponent,
    CompetenceInputComponent,
    ErrorDialogComponent,
    ArquivoComponent,
    ListarDashBoardsComponent,
    CriarDashBoardComponent,
    EditarDashBoardComponent,
    DashBoardsComponent,
    CriarCategoriaDashboardComponent,
    ListarCategoriasDashboardComponent,
  ],
  providers: [
    AccountService,
    loginService,
    QuestionarioService,
    DatePipe,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: "pt" },
  ],
  bootstrap: [AppComponent],
  imports: [
    ApplicationPipesModuleModule,
    NgxMaskModule.forRoot(),
    AngularEditorModule,
    BrowserModule,
    QuillModule.forRoot(),
    BrowserAnimationsModule,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    MatInputModule,
    A11yModule,
    ClipboardModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    PortalModule,
    ScrollingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CadastroUserModule,
    CadastroUserRoutingModule,
    FontAwesomeModule,
    NgxMatTimepickerModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    MatFormFieldModule,
    CommonModule,
    RouterModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    NgApexchartsModule,
    MomentModule,
    ModalModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    AppRoutingModule,
    InsumosModule,
  ],
})
export class AppModule {
  constructor(private library: FaIconLibrary) {
    library.addIcons(
      faSquare,
      faCheckSquare,
      faGooglePlus,
      faFacebook,
      faLinkedin,
      faYoutube,
      faTwitterSquare,
      faInstagramSquare
    );
  }
}
