import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as S3 from "aws-sdk/clients/s3";
import { AwsS3Keys } from "src/app/_models/upload";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class UploadService {
  SERVER_URL: string = "https://dattasign.com.br:9904/upload/v2";
  constructor(private http: HttpClient) {}

  validationAws() {
    return this.http.get<AwsS3Keys>(
      `${environment.apiUrl}/upload/validation-aws`
    );
  }

  uploadFileInS3(file, fileName: string, mimeType: string, dto: AwsS3Keys) {
    try {
      const bucket = new S3({
        accessKeyId: dto.accessKeyId,
        secretAccessKey: dto.secretAccessKey,
        region: dto.region,
      });

      const params = {
        Bucket: dto.bucketName,
        Key: fileName,
        Body: file,
        ACL: "private",
        ContentType: mimeType,
      };

      return bucket.upload(params, function (err, data) {
        if (err) {
          throw new Error("Erro ao fazer upload do arquivo no S3");
        }
        return true;
      });
    } catch (error) {
      throw new Error("Erro ao fazer upload do arquivo no S3");
    }
  }

  public upload(formData) {
    return this.http.post<any>(this.SERVER_URL, formData, {
      reportProgress: true,
      observe: "events",
    });
  }

  uploadPhoto(userId, formData) {
    return this.http.post<any>(
      `${environment.apiUrl}/upload/profile-photo/${userId}`,
      formData,
      {
        reportProgress: true,
        observe: "events",
      }
    );
  }

  uploadAnexo(pedidoId, formData) {
    return this.http.post<any>(
      `${environment.apiUrl}/upload/${pedidoId}`,
      formData,
      {
        reportProgress: true,
        observe: "events",
      }
    );
  }

  //uploads
  uploadTicketAnexo(formData, userId) {
    return this.http.post<any>(
      `${environment.apiUrl}/upload/anexo-ticket/${userId}`,
      formData,
      {
        reportProgress: true,
        observe: "events",
      }
    );
  }

  uploadTicketAnexoS3(userId, fileKey: string, fileName: string, id?: string) {
    return this.http.post<any>(
      `${environment.apiUrl}/upload/anexo-ticket-by-s3`,
      {
        userId: userId,
        fileKey: fileKey,
        fileName: fileName,
        id: id,
      }
    );
  }

  uploadInsumoAnexoS3(userId, fileKey: string, fileName: string) {
    return this.http.post<any>(
      `${environment.apiUrl}/upload/anexo-insumo-by-s3`,
      {
        userId: userId,
        fileKey: fileKey,
        fileName: fileName,
      }
    );
  }

  uploadAnexoInsumo(anexoInsumoDto, userId) {
    return this.http.post<any>(
      `${environment.apiUrl}/upload/anexo-insumo/${userId}`,
      anexoInsumoDto,
      {
        reportProgress: true,
        observe: "events",
      }
    );
  }

  uploadAnexoForm(anexoFormDto, userId) {
    return this.http.post<any>(
      `${environment.apiUrl}/upload/anexo-form/${userId}`,
      anexoFormDto,
      {
        reportProgress: true,
        observe: "events",
      }
    );
  }

  uploadAnexoInsumoModel(anexoInsumoDto, userId) {
    return this.http.post<any>(
      `${environment.apiUrl}/upload/anexo-model-file-insumo/${userId}`,
      anexoInsumoDto,
      {
        reportProgress: true,
        observe: "events",
      }
    );
  }

  uploadAnexoCsv(anexoDto) {
    return this.http.post<any>(
      `${environment.apiUrl}/upload/anexo-csv`,
      anexoDto,
      {
        reportProgress: true,
        observe: "events",
      }
    );
  }
  uploadAnexoInsumoCsv(anexoDto) {
    return this.http.post<any>(
      `${environment.apiUrl}/upload/anexo-csv-insumo`,
      anexoDto,
      {
        reportProgress: true,
        observe: "events",
      }
    );
  }

  uploadTicketAnexoUpload(formData, userId, ticketId) {
    return this.http.post<any>(
      `${environment.apiUrl}/upload/anexo-ticket/update/${userId}/${ticketId}`,
      formData,
      {
        reportProgress: true,
        observe: "events",
      }
    );
  }
}
