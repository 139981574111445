import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { SelectionModel } from "@angular/cdk/collections";
import { HttpClient, HttpEventType } from "@angular/common/http";
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { merge, of } from "rxjs";
import {
  catchError,
  debounceTime,
  filter,
  finalize,
  map,
  switchMap,
  tap,
} from "rxjs/operators";
import { User } from "src/app/_models";
import { AccountService } from "src/app/_services";
import { InsumoService } from "src/app/_services/insumos.service";
import { UploadService } from "src/app/_services/upload.service";
import { environment } from "src/environments/environment";
import * as uuid from "uuid";
import { filterParams, InsumoList } from "../../_models/insumos";
import { CompetenceDatePipe } from "../../_pipes/competence-date.pipe";
import { DeliveryDatePipe } from "../../_pipes/deliveryDate.pipe";
import { InsumosFieldsService } from "../../_services/insumosFields.service";
import { TablePeriodicElement } from "../insumo-field-config/insumo-field-config.component";

@Component({
  selector: "app-consultar-insumos",
  templateUrl: "./consultar-insumos.component.html",
  styleUrls: ["./consultar-insumos.component.css"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
  providers: [CompetenceDatePipe, DeliveryDatePipe],
})
export class ConsultarInsumosComponent implements OnInit, AfterViewInit {
  selection = new SelectionModel<InsumoList>(true, []);

  submitted = false;
  fields: any[] = [];
  isChecked = true;

  visualizationMode = "cards";
  insumoList = [];

  pageSize = this.visualizationMode === "list" ? 5 : 6;
  pageSizeOptions =
    this.visualizationMode === "list"
      ? [1, 5, 10, 25, 50, 100]
      : [1, 6, 12, 24, 48, 96];

  anexoInsumo: FormGroup;
  formUpdate: FormGroup;
  formDeadLine: FormGroup;
  formGroup: FormGroup;
  formFiltro: FormGroup;
  formChangeVersion: FormGroup;

  actualInsumoId: any;
  insumoVersionList: string[] = [];
  insumoSegmentList: string[] = [];
  tempInsumoId: any;
  user: User;
  userToken: string;
  insumo: InsumoList = {} as InsumoList;

  searchUserCtrl1 = new FormControl();
  searchUserCtrl2 = new FormControl();
  searchUserCtrlFiltro = new FormControl();

  errorMsg: string;
  filteredUsers1: any;
  filteredUsers2: any;
  filteredUsersFilter: any;
  isLoading = false;
  actualInsumoLength: number = 0;

  files = [];
  attachments: any[] = [];
  anexoDownload: string = environment.apiUrl + "/download/anexo-insumo/";

  formData: MatTableDataSource<InsumoList>;
  dataSourceForDownload: MatTableDataSource<any>;
  dataSource = new MatTableDataSource<InsumoList>();
  dataSourceLength: number = 0;
  isDownloadProcess: boolean = false;
  expandedElement: InsumoList;
  expandedElement2: InsumoList;

  filterParams: filterParams;

  @ViewChild("fileUpload", { static: false }) fileUpload: ElementRef;
  @ViewChild("closeModalAlterarResponsavel")
  closeModalAlterarResponsavel: ElementRef;
  @ViewChild("closeModalAlterarDeadLine")
  closeModalAlterarDeadLine: ElementRef;
  @ViewChild("closeModalExcluir") closeModalExcluir: ElementRef;
  @ViewChild("closeModalEncerrar") closeModalEncerrar: ElementRef;
  @ViewChild("closeConfirmDoc") closeConfirmDoc: ElementRef;
  @ViewChild("closeModalEncerradosAlert") closeEncerradosAlert: ElementRef;
  @ViewChild("encerradosNada") encerradosNada: ElementRef;
  @ViewChild("closeModalVersion") closeModalVersion: ElementRef;
  @ViewChild("exportModal") exportModal: ElementRef;
  @ViewChild("modalclose") modalclose: ElementRef;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private insumosService: InsumoService,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private http: HttpClient,
    private uploadService: UploadService,
    private insumoFieldService: InsumosFieldsService
  ) {
    this.user = this.accountService.userValue.user;
    this.userToken = this.accountService.userValue.token;
  }

  ngOnInit(): void {
    this.formChangeVersion = this.formBuilder.group({
      version: ["", Validators.required],
      justificativa: ["", Validators.required],
    });

    this.anexoInsumo = this.formBuilder.group({
      justify: ["", [Validators.minLength(3), Validators.maxLength(300)]],
    });

    this.formUpdate = this.formBuilder.group({
      responsibleUpload: ["", [Validators.required]],
      analysisUpload: ["", [Validators.required]],
      justify: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(300),
        ],
      ],
    });

    this.formDeadLine = this.formBuilder.group({
      deadline: ["", [Validators.required]],
      justify: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(300),
        ],
      ],
    });

    this.formFiltro = this.formBuilder.group({
      email: [""],
      segment: [""],
      insumoName: [""],
      status: [""],
      competence: [""],
      competenceStatus: [""],
      insumoId: [""],
    });

    this.searchUserCtrl1.valueChanges
      .pipe(
        filter((value) => value?.length > 3),
        debounceTime(500),
        tap(() => {
          this.errorMsg = "";
          this.filteredUsers1 = [];
          this.isLoading = true;
        }),
        switchMap((value) =>
          this.http
            .get(`${environment.apiUrl}/api/user/search?email=${value}`)
            .pipe(
              finalize(() => {
                this.isLoading = false;
              })
            )
        )
      )
      .subscribe((data) => {
        if (data == undefined) {
          this.errorMsg = data["Error"];
          this.filteredUsers1 = [];
        } else {
          this.errorMsg = "";
          this.filteredUsers1 = data;
        }
      });

    this.searchUserCtrl2.valueChanges
      .pipe(
        filter((value) => value?.length > 3),
        debounceTime(500),
        tap(() => {
          this.errorMsg = "";
          this.filteredUsers2 = [];
          this.isLoading = true;
        }),
        switchMap((value) =>
          this.http
            .get(`${environment.apiUrl}/api/user/search?email=${value}`)
            .pipe(
              finalize(() => {
                this.isLoading = false;
              })
            )
        )
      )
      .subscribe((data) => {
        if (data == undefined) {
          this.errorMsg = data["Error"];
          this.filteredUsers2 = [];
        } else {
          this.errorMsg = "";
          this.filteredUsers2 = data;
        }
      });

    this.searchUserCtrlFiltro.valueChanges
      .pipe(
        filter((value) => value?.length > 3),
        debounceTime(500),
        tap(() => {
          this.errorMsg = "";
          this.filteredUsersFilter = [];
          this.isLoading = true;
        }),
        switchMap((value) =>
          this.http
            .get(`${environment.apiUrl}/api/user/search?email=${value}`)
            .pipe(
              finalize(() => {
                this.isLoading = false;
              })
            )
        )
      )
      .subscribe((data) => {
        if (data == undefined) {
          this.errorMsg = data["Error"];
          this.filteredUsersFilter = [];
        } else {
          this.errorMsg = "";
          this.filteredUsersFilter = data;
        }
      });

    this.getInsumosFieldsSegmento();
    this.resetFilterParams();
    this.OnInitializeDataSource();
  }

  ngAfterViewInit() {
    this.paginator.pageIndex = 0;
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.filter()))
      .subscribe();
  }

  filter() {
    this.validationFilterRequisition();
    this.insumosService
      .getInsumoPageable(
        this.paginator.pageIndex,
        this.paginator.pageSize,
        this.sort.active,
        this.sort.direction,
        this.filterParams.name,
        this.filterParams.userId,
        this.filterParams.email,
        this.filterParams.segment,
        this.filterParams.status,
        this.filterParams.competence,
        this.filterParams.competenceStatus,
        this.filterParams.id
      )
      .subscribe((data) => {
        this.dataSource = new MatTableDataSource(data["content"]);
        this.insumoList = data.content;
        this.dataSourceLength = data.totalElements;
        this.formData = this.dataSource;
      });
  }

  changeVisualizationMode(mode) {
    this.visualizationMode = mode.value;

    this.pageSize = this.visualizationMode === "list" ? 5 : 6;
    this.pageSizeOptions =
      this.visualizationMode === "list"
        ? [1, 5, 10, 25, 50, 100]
        : [1, 6, 12, 24, 48, 96];
  }

  OnInitializeDataSource() {
    this.insumosService
      .getInsumoPageable(
        0,
        this.pageSize,
        "id",
        "desc",
        this.filterParams.name,
        this.filterParams.userId,
        this.filterParams.email,
        this.filterParams.segment,
        this.filterParams.status,
        this.filterParams.competence,
        this.filterParams.competenceStatus,
        this.filterParams.id
      )
      .subscribe((data) => {
        this.dataSource = new MatTableDataSource(data["content"]);
        this.insumoList = data.content;
        this.dataSourceLength = data.totalElements;
        this.formData = this.dataSource;
      });
  }

  resetFilterParams() {
    this.filterParams = {
      id: 0,
      userId: 0,
      name: "TODOS",
      email: "TODOS",
      status: "TODOS",
      segment: "TODOS",
      competence: "TODOS",
      competenceStatus: "TODOS",
    };
  }

  onReset() {
    this.formFiltro.reset();
    this.searchUserCtrlFiltro.reset();
    this.resetFilterParams();
    this.OnInitializeDataSource();
  }

  validationFilterRequisition() {
    const filtro = this.formFiltro.value;

    if (filtro.insumoName === "" || filtro.insumoName === null) {
      filtro.insumoName = "TODOS";
    }

    if (filtro.userId === "" || filtro.userId === null) {
      filtro.userId = this.user.id;
    }

    if (filtro.email === "" || filtro.email === null) {
      filtro.email = "TODOS";
    }

    if (filtro.segment === "" || filtro.segment === null) {
      filtro.segment = "TODOS";
    }

    if (filtro.status === "" || filtro.status === null) {
      filtro.status = "TODOS";
    }

    if (filtro.competenceStatus === "" || filtro.competenceStatus === null) {
      filtro.competenceStatus = "TODOS";
    }

    if (filtro.competence === "" || filtro.competence === null) {
      filtro.competence = "TODOS";
    }

    if (filtro.insumoId === "" || filtro.insumoId === null) {
      filtro.insumoId = 0;
    }

    this.filterParams = {
      email: filtro.email,
      segment: filtro.segment,
      name: filtro.insumoName,
      status: filtro.status,
      competence: filtro.competence,
      competenceStatus: filtro.competenceStatus,
      id: filtro.insumoId,
      userId: 0,
    };
  }

  getInsumosFieldsSegmento() {
    this.insumoFieldService.getByType("SEGMENTO").subscribe((data) => {
      this.insumoSegmentList = data
        .map((item: TablePeriodicElement) => item.fieldDescription)
        .filter((value) => value !== "Todos");
    });
  }

  async getInsumosForDownload() {
    const filtro = this.formFiltro.value;
    this.validationFilterRequisition();

    await this.insumosService
      .getInsumoPageable(
        0,
        -1,
        this.sort.active,
        this.sort.direction,
        filtro.insumoName,
        0,
        filtro.email,
        filtro.segment,
        filtro.status,
        filtro.competence,
        filtro.competenceStatus,
        filtro.insumoId
      )
      .toPromise()
      .then((data) => {
        this.dataSourceForDownload = new MatTableDataSource(data["content"]);
      });
  }

  exportToExcel(model: string) {
    const filtro = this.formFiltro.value;
    this.validationFilterRequisition();
    const userId = 0;

    this.accountService.getTokenWithLowExpiration().subscribe((data) => {
      const link = `${environment.apiUrl}/insumo/export?&name=${filtro.insumoName}&userId=${userId}&email=${filtro.email}&segment=${filtro.segment}&status=${filtro.status}&competence=${filtro.competence}&insumoId=${filtro.insumoId}&competenceStatus=${filtro.competenceStatus}&model=${model}&token=${data.token}`;

      window.open(link, "_blank");
    });
  }

  uploadAnexoInsumo(insumo: any) {
    this.insumo = insumo;
    this.actualInsumoLength = insumo.attachments.length;
  }

  selectInsumoVersion(insumo: any) {
    this.insumo = insumo;
    this.insumo["insumoAnexos"].forEach((element) => {
      this.insumoVersionList.push("v" + element.version);
    });

    return;
  }

  insumoAtraso(element) {
    const date = new Date().getTime();
    const deadline = new Date(element.deadline).getTime();

    if (date > deadline) {
      return true;
    }

    return false;
  }

  editPermission(responsibleUser?: string) {
    if (
      responsibleUser === this.user.email ||
      this.user.userType == "EQUIPE_INTERNA" ||
      this.user.userType == "OPERACAO_BPO"
    ) {
      return false;
    }

    return true;
  }

  isOperador() {
    if (this.user.userType === "OPERACAO_VIVO") {
      return false;
    } else if (this.user.userType === "OPERADOR_VIVO_JURIDICO") {
      return false;
    } else if (this.user.userType === "OPERADOR_VIVO_AUDITORIA") {
      return false;
    } else {
      return true;
    }
  }

  populateInsumo(data: InsumoList) {
    this.insumo = data;
  }

  onSubmit(): void {
    let updateAnexosInsumoDto: any = {};

    if (this.actualInsumoLength > 0) {
      if (this.anexoInsumo.invalid) {
        return;
      }

      updateAnexosInsumoDto = {
        userId: this.user.id,
        anexos: this.attachments,
        justificativa: this.anexoInsumo.get("justify").value,
        insumoId: this.insumo.id,
      };
      this.insumosService
        .updateAnexos(updateAnexosInsumoDto)
        .subscribe(() => this.OnInitializeDataSource());

      this.anexoInsumo.reset();
    } else {
      updateAnexosInsumoDto = {
        userId: this.user.id,
        anexos: this.attachments,
        justificativa: "",
        insumoId: this.insumo.id,
      };

      this.insumosService
        .updateAnexos(updateAnexosInsumoDto)
        .subscribe(() => this.OnInitializeDataSource());
    }
    this.attachments = [];
    this.files = [];
    this.closeConfirmDoc.nativeElement.click();
  }

  get formUp(): { [key: string]: AbstractControl } {
    return this.formUpdate.controls;
  }

  desativarInsumo(insumoId: any) {
    this.insumosService.inativarInsumo(insumoId).subscribe(() => {
      this.OnInitializeDataSource();
    });
  }

  getInsumoById(id) {
    this.insumosService.getInsumoById(id).subscribe((data) => {
      this.insumo = data as unknown as InsumoList;
      this.actualInsumoLength = data.attachments.length;
    });
  }

  excluirInsumo() {
    this.insumosService.deleteInsumo(this.tempInsumoId).subscribe((data) => {
      this.OnInitializeDataSource();
      this.tempInsumoId = null;
      this.closeModalExcluir.nativeElement.click();
    });
  }

  encerrarInsumos() {
    const userType = this.user.userType;

    if (this.selection.selected.length <= 0) {
      this.closeModalEncerrar.nativeElement.click();
      this.encerradosNada.nativeElement.click();
      return;
    }

    this.selection.selected.forEach((element, index) => {
      if (userType == "EQUIPE_INTERNA" || userType == "OPERACAO_BPO") {
        const closeInsumoDto = {
          insumoId: element.id,
          userId: this.user.id,
        };

        this.insumosService.closeInsumo(closeInsumoDto).subscribe(() => {
          if (index === this.selection.selected.length - 1) {
            this.OnInitializeDataSource();
            this.selection.clear();
            this.closeModalEncerrar.nativeElement.click();
          }
        });
      }
    });
  }

  onSubmitUpdate(): void {
    this.submitted = true;

    if (this.formUpdate.invalid) {
      return;
    }

    let tempAlterarResponsavel: any = {
      insumosId: this.actualInsumoId,
      responsibleUser: this.user.email,
      responsibleUpload: this.formUpdate.get("responsibleUpload").value,
      analysisUpload: this.formUpdate.get("analysisUpload").value,
      justify: this.formUpdate.get("justify").value,
    };

    this.submitted = false;
    this.updatedInsumoResponsavel(tempAlterarResponsavel);
  }

  onSubmitDeadLine(): void {
    this.submitted = true;

    if (this.formDeadLine.invalid) {
      return;
    }

    let tempAlterarDeadLine: any = {
      insumosId: this.actualInsumoId,
      responsibleUser: this.user.email,
      deadline: this.formDeadLine.get("deadline").value,
      justify: this.formDeadLine.get("justify").value,
    };

    this.submitted = false;
    this.updatedInsumoDeadline(tempAlterarDeadLine);
  }

  setEmailResponsibleUpload(user) {
    this.formUpdate.get("responsibleUpload").setValue(user.email);
  }

  setEmailAnalysisUpload(user) {
    this.formUpdate.get("analysisUpload").setValue(user.email);
  }

  setEmailResponsavel(user) {
    this.formFiltro.get("email").setValue(user.email);
  }

  resetField(form) {
    if (form === 1) {
      this.formUpdate.get("responsibleUpload").reset();
      this.searchUserCtrl1.reset();
    } else if (form === 2) {
      this.formUpdate.get("analysisUpload").reset();
      this.searchUserCtrl2.reset();
    } else {
      this.formFiltro.get("analysisUpload").reset();
      this.searchUserCtrlFiltro.reset();
    }
  }

  updatedInsumoResponsavel(insumoResponsavelDto) {
    this.insumosService
      .updateResponsavel(insumoResponsavelDto)
      .subscribe(() => {
        this.closeModalAlterarResponsavel.nativeElement.click();
        this.actualInsumoId = null;
        this.OnInitializeDataSource();
      });
  }

  updatedInsumoDeadline(insumoDeadLineDto) {
    this.insumosService.updateDeadLine(insumoDeadLineDto).subscribe(() => {
      this.closeModalAlterarDeadLine.nativeElement.click();
      this.actualInsumoId = null;
      this.OnInitializeDataSource();
    });
  }

  //uploads

  somArray = (list: any[]) => {
    let total = 0;
    list.map((item) => (total += item.progress));

    return total / list.length;
  };

  removeAnexoFormPreUpload(file: any) {
    this.attachments = this.attachments.filter(
      (item) => item.originalFileName !== file.originalFileName
    );
    this.files = this.files.filter(
      (item) => item.data.name !== file.originalFileName
    );
  }

  removeFile(file: any) {
    this.attachments = this.attachments.filter(
      (item) => item.originalFileName !== file.originalFileName
    );
    this.files = this.files.filter(
      (item) => item.data.name !== file.originalFileName
    );
  }

  uploadFile(file) {
    const formData = new FormData();
    formData.append("file", file.data);
    file.inProgress = true;
    const fileBytes = file.data.size;

    if (fileBytes > 10000000) {
      this.uploadService.validationAws().subscribe((keys) => {
        const splitFileName = String(file.data.name).split(".");
        const mimeType = splitFileName[splitFileName.length - 1];
        const fileKey = uuid.v4();
        const fileName = String(file.data.name);

        this.uploadService
          .uploadFileInS3(file.data, fileKey, mimeType, keys)
          .on("httpUploadProgress", function (progress) {
            let progressPercentage = Math.round(
              (progress.loaded / progress.total) * 100
            );
            file.progress = progressPercentage;
          })
          .promise()
          .then(() => {
            this.uploadService
              .uploadInsumoAnexoS3(this.user.id, fileKey, fileName)
              .subscribe((body) => {
                this.attachments.push(body);
              });
          });
      });
    } else {
      this.uploadService
        .uploadAnexoInsumo(formData, this.user.id)
        .pipe(
          map((event) => {
            switch (event.type) {
              case HttpEventType.UploadProgress:
                file.progress = Math.round((event.loaded * 100) / event.total);
                break;
              case HttpEventType.Response:
                return event;
            }
          }),
          catchError(() => {
            file.inProgress = false;
            return of(`${file.data.name} upload failed.`);
          })
        )
        .subscribe((event: any) => {
          if (typeof event === "object") {
            this.attachments.push(event.body);
          }
        });
    }
  }

  private uploadFiles() {
    this.fileUpload.nativeElement.value = "";
    this.files.forEach((file) => {
      this.uploadFile(file);
    });
  }

  onUploadButtonClick() {
    this.files = [];
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.files.push({ data: file, inProgress: false, progress: 0 });
      }
      this.uploadFiles();
    };
    // fileUpload.click();
  }

  //uploads
  downloadFileNotification(fileId, insumoid) {
    this.insumosService
      .downloadNotification(this.user.id, insumoid, fileId)
      .subscribe(() => {
        console.log("Send notification");
      });

    this.downloadFile(fileId);
  }

  downloadFile(fileId: any) {
    this.accountService.getTokenWithLowExpiration().subscribe((data) => {
      const urlToDownloadFile: string = `${this.anexoDownload}${fileId}?token=${data.token}`;

      window.open(urlToDownloadFile);
    });
  }

  downloadFileInsumoContent(data: any) {
    const insumoVersion: number = Number(data.version.replace("v", ""));
    const file = data.attachments.find(
      (insumo) => insumo.version === insumoVersion
    );

    this.insumosService
      .downloadNotification(this.user.id, data.id, file.id)
      .subscribe(() => console.log("Send notification"));
    this.downloadFile(file.id);
  }
  changeVersion() {
    if (this.formChangeVersion.invalid) {
      return;
    }

    const insumoVersionDto = {
      insumosId: this.actualInsumoId,
      userId: this.user.id,
      version: this.formChangeVersion.get("version").value,
      justificativa: this.formChangeVersion.get("justificativa").value,
    };

    this.insumosService.updateVersion(insumoVersionDto).subscribe(() => {
      this.closeModalVersion.nativeElement.click();
      this.formChangeVersion.reset();
      this.actualInsumoId = null;
      this.OnInitializeDataSource();
    });
  }

  getInsumoWithVersion(insumoAnexos: any[], version: string) {
    return insumoAnexos.find((item) => item.version == version);
  }

  //table Content
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;

    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  checkboxLabel(row?: InsumoList): string {
    if (!row) {
      return `${this.isAllSelected() ? "deselect" : "select"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${
      row.id + 1
    }`;
  }

  columnsToDisplay = [
    "select",
    "id",
    "name",
    "publico",
    "segment",
    "competence",
    "deadline",
    "version",
    "dataDeUpload",
    "responsavel",
    "actions",
  ];

  displayedColumnsInterno: string[] = [
    "idArquivo",
    "nomeArquivo",
    "Justificativa",
    "dataEHoraEnvio",
    "versao",
    "acoes",
  ];
}
