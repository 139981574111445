import { environment } from 'src/environments/environment';

import { Injectable } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';

import { InsumoChart, InsumoBarChart, Insumo } from '../_models/insumos';

@Injectable({
  providedIn: "root",
})
export class InsumoService {
  constructor(private http: HttpClient) {}

  saveInsumo(insumoPersistDto) {
    return this.http.post<any>(
      `${environment.apiUrl}/insumo`,
      insumoPersistDto
    );
  }

  getInsumoById(insumoId) {
    return this.http.get<Insumo>(`${environment.apiUrl}/insumo/${insumoId}`);
  }

  getAllInsumos() {
    return this.http.get<any>(`${environment.apiUrl}/insumo`);
  }

  getInsumoByName(name) {
    return this.http.post<any>(`${environment.apiUrl}/insumo/search/name`, {
      name: name,
    });
  }

  getInsumoPageable(
    page: number,
    size: number,
    sort: string,
    order: string,
    name: string,
    userId: number,
    email: string,
    segment: string,
    status: string,
    competence: string,
    competenceStatus: string,
    insumoId: number
  ) {
    return this.http.get<any>(
      `${environment.apiUrl}/insumo/search/filter/${page}/${size}`,
      {
        params: new HttpParams()
          .set("sort", sort)
          .set("order", order)
          .set("name", name)
          .set("userId", userId)
          .set("email", email)
          .set("segment", segment)
          .set("status", status)
          .set("competence", competence)
          .set("competenceStatus", competenceStatus)
          .set("insumoId", insumoId),
      }
    );
  }

  getAllInsumoHasParentPageable(
    page: number,
    size: number,
    sort: string,
    order: string,
    name: string,
    userId: number,
    email: string,
    segment: string,
    status: string,
    competence: string,
    competenceStatus: string,
    insumoId: number){
      return this.http.get<any>(
        `${environment.apiUrl}/insumo/search/has-parent/${page}/${size}`,
        {
          params: new HttpParams()
            .set("sort", sort)
            .set("order", order)
            .set("name", name)
            .set("userId", userId)
            .set("email", email)
            .set("segment", segment)
            .set("status", status)
            .set("competence", competence)
            .set("competenceStatus", competenceStatus)
            .set("insumoId", insumoId),
        }
      );
  }

  createAnexo(anexoInsumoDto, userId) {
    return this.http.post<any>(
      `${environment.apiUrl}/anexo-insumo/${userId}`,
      anexoInsumoDto
    );
  }

  deleteInsumo(id) {
    return this.http.delete<any>(`${environment.apiUrl}/insumo/${id}`);
  }

  closeInsumo(closeInsumoDto) {
    return this.http.put<any>(
      `${environment.apiUrl}/insumo/close`,
      closeInsumoDto
    );
  }

  insumoAddNumeroSd(insumoAddNumeroSdDto) {
    return this.http.put<any>(
      `${environment.apiUrl}/insumo/addNumeroSd`,
      insumoAddNumeroSdDto
    );
  }

  updateResponsavel(insumoResponsavelDto) {
    return this.http.put<any>(
      `${environment.apiUrl}/insumo/responsavel`,
      insumoResponsavelDto
    );
  }

  updateHasInsumo(hasInsumoDto) {
    return this.http.put<any>(
      `${environment.apiUrl}/insumo/hasInsumo`,
      hasInsumoDto
    );
  }

  updateAllowNewUpload(allowNewInsumoDto) {
    return this.http.put<any>(
      `${environment.apiUrl}/insumo/allowNewUpload`,
      allowNewInsumoDto
    );
  }

  updateDeadLine(insumoDeadLineDto) {
    return this.http.put<any>(
      `${environment.apiUrl}/insumo/deadline`,
      insumoDeadLineDto
    );
  }

  updateDeadLineForActual(insumoDeadLineDto) {
    return this.http.put<any>(
      `${environment.apiUrl}/insumo/deadline-actual`,
      insumoDeadLineDto
    );
  }

  updateAnexos(insumoAnexoUploadDto) {
    return this.http.post<any>(
      `${environment.apiUrl}/insumo/anexos-update`,
      insumoAnexoUploadDto
    );
  }

  updateAnexosModelFile(insumoAnexoModelFileDto) {
    return this.http.post<any>(
      `${environment.apiUrl}/insumo/anexo-model-file-insumo`,
      insumoAnexoModelFileDto
    )
  }

  removeAnexoModelFileInsumo(insumoAnexoModelFileDto) {
    return this.http.put<any>(
      `${environment.apiUrl}/insumo/remove-anexo-model-file-insumo`,
      insumoAnexoModelFileDto
    )
  }

  removeAllAnexoModelFileInsumo(insumoId) {
    return this.http.put<any>(
      `${environment.apiUrl}/insumo/remove-all-anexo-model-file-insumo`,
      insumoId
    )
  }

  inativarInsumo(insumoDto) {
    return this.http.put<any>(
      `${environment.apiUrl}/insumo/inactivate`,
      insumoDto
    );
  }

  downloadNotification(userid, insumoid, fileid) {
    return this.http.get<any>(
      `${environment.apiUrl}/insumo/download/${userid}/${insumoid}/${fileid}`
    );
  }

  downloadAnexoModelFileNotification(userid, insumoid, fileid) {
    return this.http.get<any>(
      `${environment.apiUrl}/insumo/download/anexo-model-file/${userid}/${insumoid}/${fileid}`
    );
  }

  updateVersion(insumoVersionDto) {
    return this.http.put<any>(
      `${environment.apiUrl}/insumo/version`,
      insumoVersionDto
    );
  }

  updateValidators(insumoValidatorsDto) {
    return this.http.put<any>(
      `${environment.apiUrl}/insumo/add-validators`,
      insumoValidatorsDto
    );
  }

  removeValidators(insumoValidatorsDto) {
    return this.http.put<any>(
      `${environment.apiUrl}/insumo/remove-validators`,
      insumoValidatorsDto
    );
  }

  updateFrequency(insumoFrequencyDto) {
    return this.http.put<any>(
      `${environment.apiUrl}/insumo/frequency`,
      insumoFrequencyDto
    );
  }

  updateInsumo(insumoPersistDto) {
    return this.http.put<any>(
      `${environment.apiUrl}/insumo/update`,
      insumoPersistDto
    );
  }

  getAllInsumosChart() {
    return this.http.get<InsumoChart>(`${environment.apiUrl}/insumo/all-chart`);
  }
  getAllInsumosBarChart() {
    return this.http.get<InsumoBarChart>(
      `${environment.apiUrl}/insumo/all-bar-chart/`
    );
  }
}
