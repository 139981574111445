<p class="text-muted mt-4 mb-1">
  <span class="text-primary">Portal de Comissionamento</span>
</p>

<h1 class="text-muted">Todos os Insumos</h1>

<form [formGroup]="formFiltro">
  <div class="row mb-3">
    <div class="col-sm-4">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Nome do Insumo: </mat-label>
        <input type="text" matInput formControlName="insumoName" />
      </mat-form-field>
    </div>

    <div class="col-sm-4">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Email do Responsável: </mat-label>
        <input
          type="text"
          matInput
          autocomplete="new-responsavel"
          aria-label="Email do(a) Responsável"
          [matAutocomplete]="autoFiltro"
          [formControl]="searchUserCtrlFiltro"
          autocomplete="off"
          (click)="resetField(3)"
        />
        <mat-autocomplete #autoFiltro="matAutocomplete">
          <mat-option *ngIf="isLoading" class="is-loading">
            Carregando...
          </mat-option>
          <ng-container *ngIf="!isLoading">
            <mat-option
              *ngFor="let user of filteredUsersFilter"
              [value]="user.email"
              [value]="user.email"
              (click)="setEmailResponsavel(user)"
            >
              {{ user.email }}
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="col-sm-4">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Status da Competência: </mat-label>
        <mat-select formControlName="competenceStatus" [disabled]="true">
          <mat-option value="VIGENTE">Vigente</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-sm-3">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Segmento:</mat-label>
        <mat-select formControlName="segment">
          <mat-option
            *ngFor="let segment of insumoSegmentList"
            [value]="segment"
          >
            {{ segment }}
          </mat-option>
          <mat-option value="TODOS">Todos</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-sm-3">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Status: </mat-label>
        <mat-select formControlName="status">
          <mat-option value="ATIVO">Ativo</mat-option>
          <mat-option value="INATIVO">Inativo</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-sm-3">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Competência: </mat-label>
        <input
          type="text"
          matInput
          formControlName="competence"
          placeholder="MM/YYYY"
          mask="00/0000"
          [dropSpecialCharacters]="false"
        />
      </mat-form-field>
    </div>
    <div class="col-sm-3">
      <mat-form-field class="w-100" appearance="fill">
        <mat-label>Consulta por Id: </mat-label>
        <input type="text" matInput formControlName="insumoId" />
      </mat-form-field>
    </div>
    <div class="col-sm-12">
      <button
        class="btn btn-sm btn-primary"
        (click)="filter(); this.paginator.firstPage()"
      >
        <div class="button-flex">
          <mat-icon>filter_alt</mat-icon>
          Filtrar
        </div>
      </button>
      <button class="btn btn-secondary btn-sm ml-2" (click)="onReset()">
        <div class="button-flex">
          <mat-icon>backspace</mat-icon>
          Limpar
        </div>
      </button>
      <button
        type="button"
        class="btn btn-rose btn-sm ml-2"
        data-toggle="modal"
        data-target="#export"
      >
        <div class="button-flex">
          <mat-icon>file_download</mat-icon>
          Exportar
        </div>
      </button>

      <!-- <button
        type="button"
        class="btn btn-sm btn-warning ml-2"
        data-toggle="modal"
        data-target="#importarInsumos"
        [hidden]="
          this.user.email === 'sidney@media4all.com' ||
          this.user.email == 'juan@media4all.com.br' ||
          this.user.email == 'victor@media4all.com.br'
        "
      >
        <div class="button-flex">
          <mat-icon class="mr-1">post_add</mat-icon>
          Importar Insumos
        </div>
      </button> -->
    </div>

    <div class="col-sm-12 mt-4 mb-0">
      <button
        [hidden]="editPermission()"
        [disabled]="filterParams.status === 'INATIVO'"
        matTooltip="Encerrar"
        data-toggle="modal"
        data-target="#encerrar"
        type="button"
        class="btn btn-rose btn-sm ml-0"
      >
        <div class="button-flex">
          <mat-icon>file_download</mat-icon>
          Encerrar Insumos Selecionados
        </div>
      </button>

      <button
        #encerradosNada
        data-toggle="modal"
        data-target="#encerradosnada"
        type="button"
        class="btn btn-rose btn-sm ml-0"
        style="display: none"
      ></button>
    </div>
  </div>

  <div class="modal fade" id="numerosd">
    <form [formGroup]="formAddNumeroSd" (ngSubmit)="addNumeroSd()">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <p class="modal-title mb-0" id="TituloModalCentralizado">
              Adicionar Número SD
            </p>
            <button
              #closeModal
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Fechar"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="my-4 px-4">
              <h3 class="text-center text-muted mt-4">
                Digite o Número SD referente à esse Insumo
              </h3>

              <mat-form-field appearance="fill" class="w-100" required>
                <mat-label> Número SD:</mat-label>
                <input
                  matInput
                  formControlName="numeroSd"
                  type="text"
                  placeholder="Digite o Número SD"
                />
              </mat-form-field>
            </div>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn-sm btn btn-secondary mr-2 btn-mat-icon"
              data-dismiss="modal"
            >
              <mat-icon class="modal-icon">arrow_back</mat-icon>
              Voltar
            </button>
            <button
              type="submit"
              class="btn-sm btn btn-success mr-2 btn-mat-icon"
            >
              <mat-icon class="modal-icon mr-1">save</mat-icon>
              Salvar Número SD
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</form>

<div class="row mb-5">
  <div class="col-sm-12">
    <p>Lista de Insumos:</p>

    <table
      id="FormTable"
      mat-table
      [dataSource]="dataSource"
      matSort
      matSortActive="id"
      matSortDirection="desc"
      matSortDisableClear
      class="mat-elevation-z8"
    >
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef class="select">
          <mat-checkbox
            color="primary"
            (change)="$event ? toggleAllRows() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            [aria-label]="checkboxLabel()"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row" class="select">
          <mat-checkbox
            color="primary"
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
            [aria-label]="checkboxLabel(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="id">
        <th class="id" mat-header-cell *matHeaderCellDef>Id.</th>
        <td class="id" mat-cell *matCellDef="let element">
          {{ element.id }}
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th class="name" mat-header-cell *matHeaderCellDef>Nome Insumo</th>
        <td class="name" mat-cell *matCellDef="let element">
          {{ element.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="publico">
        <th class="publico" mat-header-cell *matHeaderCellDef>Público</th>
        <td class="publico" mat-cell *matCellDef="let element">
          {{ element.publico | cap }}
        </td>
      </ng-container>

      <ng-container matColumnDef="segment">
        <th class="segment" mat-header-cell *matHeaderCellDef>Segmento</th>
        <td class="segment" mat-cell *matCellDef="let element">
          {{ element.segment }}
        </td>
      </ng-container>

      <ng-container matColumnDef="competence">
        <th class="competence" mat-header-cell *matHeaderCellDef>
          Competencia
        </th>
        <td class="competence" mat-cell *matCellDef="let element">
          {{ element.competence | competenceDate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="deadline">
        <th class="deadline" mat-header-cell *matHeaderCellDef>Data Limite</th>
        <td class="deadline" mat-cell *matCellDef="let element">
          {{ element.deadline }}
        </td>
      </ng-container>

      <ng-container matColumnDef="dataDeUpload">
        <th mat-header-cell *matHeaderCellDef>Data de entrega</th>
        <td mat-cell *matCellDef="let element">
          {{ element | deliveryDateInsumo }}
        </td>
      </ng-container>

      <ng-container matColumnDef="responsavel">
        <th mat-header-cell *matHeaderCellDef>Responsável</th>
        <td mat-cell *matCellDef="let element">
          {{ element.responsibleUploadFullName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="version">
        <th class="version" mat-header-cell *matHeaderCellDef>Versão</th>
        <td class="version" mat-cell *matCellDef="let element">
          {{ element.attachments.length }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th class="actions" mat-header-cell *matHeaderCellDef>Ações</th>
        <td class="actions" mat-cell *matCellDef="let element">
          <span *ngIf="user.userType === 'EQUIPE_INTERNA'">
            <button
              matSuffix
              matTooltip="Histórico"
              data-toggle="modal"
              data-target="#historico"
              class="btn btn-sm btn-dark mr-2"
              (click)="getInsumoHistory(element.id)"
            >
              <mat-icon class="mr-1">history</mat-icon>
            </button>
          </span>

          <span *ngIf="user.userType === 'EQUIPE_INTERNA'">
            <button
              [hidden]="editPermission()"
              matTooltip="Alterar Responsavel"
              data-toggle="modal"
              data-target="#update"
              class="btn btn-sm btn-success mr-2"
              (click)="getInsumosById(element.id)"
            >
              <mat-icon class="mr-1">account_circle</mat-icon>
            </button>
          </span>

          <span *ngIf="user.userType === 'OPERACAO_BPO'">
            <button
              [hidden]="editPermission()"
              matTooltip="Alterar Periodicidade"
              data-toggle="modal"
              data-target="#frequency"
              class="btn btn-sm btn-primary mr-2"
              (click)="getInsumosById(element.id)"
            >
              <mat-icon class="mr-1">loop</mat-icon>
            </button>
          </span>

          <span *ngIf="user.userType === 'EQUIPE_INTERNA'">
            <button
              [hidden]="editPermission()"
              matTooltip="Atualizar Insumo"
              data-toggle="modal"
              data-target="#updateInsumo"
              class="btn btn-sm btn-secondary mr-2"
              (click)="actualInsumoId = element.id; populateInsumoUpdateModal()"
            >
              <mat-icon class="mr-1">settings</mat-icon>
            </button>
          </span>

          <span *ngIf="user.userType === 'EQUIPE_INTERNA'">
            <button
              matTooltip="Data Limite do Insumo"
              data-toggle="modal"
              data-target="#dataLimite"
              class="btn btn-sm btn-info mr-2"
              (click)="actualInsumoId = element.id"
            >
              <mat-icon class="mr-1">calendar_month</mat-icon>
            </button>
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let element; columns: columnsToDisplay"></tr>
    </table>
    <mat-paginator
      [length]="dataSourceLength"
      [pageSize]="5"
      [pageSizeOptions]="[5, 10, 25, 50, 100]"
      showFirstLastButtons
      aria-label="Select page of periodic elements"
    >
    </mat-paginator>
  </div>
</div>

<div class="modal fade" id="updateInsumo">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title mb-0" id="TituloModalCentralizado">
          Mais configurações
        </p>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          #closeModalUpdateInsumo
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form [formGroup]="insumoUpdate" (ngSubmit)="onSubmitConfig()">
        <div class="modal-body">
          <mat-form-field class="w-100" appearance="fill">
            <mat-label>Nome do Insumo:</mat-label>
            <input matInput type="text" formControlName="name" required />
          </mat-form-field>

          <mat-form-field class="w-100" appearance="fill">
            <mat-label>Segmento:</mat-label>
            <mat-select formControlName="segment" required>
              <mat-option
                *ngFor="let segment of segmentoOptions"
                [value]="segment.fieldDescription"
              >
                {{ segment.fieldDescription }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="w-100" appearance="fill">
            <mat-label>Público:</mat-label>
            <mat-select formControlName="publico" required>
              <mat-option
                *ngFor="let publico of publicoOptions"
                [value]="publico.fieldDescription"
              >
                {{ publico.fieldDescription }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="w-100" appearance="fill">
            <mat-label>Área:</mat-label>
            <mat-select formControlName="area" required>
              <mat-option
                *ngFor="let area of areaOptions"
                [value]="area.fieldDescription"
              >
                {{ area.fieldDescription }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="w-100" appearance="fill">
            <mat-label>Tipo de Insumo: </mat-label>
            <mat-select formControlName="insumoType" required>
              <mat-option
                *ngFor="let insumoType of tipoDeInsumoOptions"
                [value]="insumoType.fieldDescription"
              >
                {{ insumoType.fieldDescription }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="w-100" appearance="fill">
            <mat-label>Observações:</mat-label>
            <textarea
              matInput
              type="text"
              formControlName="observations"
              required
            ></textarea>
          </mat-form-field>

          <competence-input
            [competenceValue]="this.insumoCompetenceDate"
            (competenceValueConverted)="setCompetenceFormValue($event)"
          ></competence-input>

          <mat-label class="text-muted mr-auto">
            Documentos Aceitos:
          </mat-label>
          <div style="margin: 10px 0" formGroupName="docTypeCheckboxGroup">
            <mat-checkbox class="w-100" formControlName="docTypeCheck1">
              XLS
            </mat-checkbox>
            <mat-checkbox class="w-100" formControlName="docTypeCheck2">
              XLSX
            </mat-checkbox>
            <mat-checkbox class="w-100" formControlName="docTypeCheck3">
              CSV
            </mat-checkbox>
            <mat-checkbox class="w-100" formControlName="docTypeCheck4">
              PDF
            </mat-checkbox>
            <mat-checkbox class="w-100" formControlName="docTypeCheck5">
              Todos
            </mat-checkbox>
          </div>

          <mat-form-field class="w-100" appearance="fill">
            <mat-label>Quantidade de Insumos permitidos: </mat-label>
            <input
              matInput
              type="number"
              appNumbersOnly
              formControlName="insumoAmount"
            />
          </mat-form-field>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn-sm btn btn-dark mr-2 btn-mat-icon"
            data-dismiss="modal"
          >
            <mat-icon>close</mat-icon>
            Cancelar
          </button>
          <button
            class="btn-sm btn btn-success btn-mat-icon"
            type="submit"
            [disabled]="insumoUpdate.invalid"
          >
            <mat-icon>done</mat-icon>
            Atualizar
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="historico"
  tabindex="-1"
  role="dialog"
  aria-labelledby="historico"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title m-0">Histórico</h3>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-sm-12">
            <section id="cd-timeline" *ngIf="insumos.history" class="">
              <div
                class="cd-timeline-block"
                *ngFor="let element of insumos.history.reverse()"
              >
                <div class="cd-timeline-img cd-picture"></div>
                <div class="cd-timeline-content bg-white">
                  <div>
                    <p class="text-primary mb-0 text-small">
                      <strong>Ação:</strong>
                    </p>
                    <h3 class="text-muted mb-0">
                      {{ element.actionEnum | cap }}
                    </h3>
                  </div>

                  <hr class="mt-2 mb-3" />

                  <div>
                    <p class="text-primary text-small mt-2 mb-0">
                      Usuário da ação:
                    </p>
                    <h3 class="text-muted mb-0 mt-0">
                      {{ element.actionUserFullName }}
                    </h3>
                    <p class="text-muted mt-0">
                      {{ element.actionUserEmail }}
                    </p>
                    <p
                      class="text-primary text-small mt-2 mb-0"
                      *ngIf="element.newResponsibleEmail"
                    >
                      Novo Responsável pelo upload:
                    </p>
                    <h3 class="text-muted mb-0 mt-0">
                      {{ element.newResponsibleFullName }}
                    </h3>
                    <p class="text-muted mt-0">
                      {{ element.newResponsibleEmail }}
                    </p>
                    <p
                      class="text-primary text-small mt-2 mb-0"
                      *ngIf="element.newAnalysisUploadEmail"
                    >
                      Novo Responsável pela Análise:
                    </p>
                    <h3 class="text-muted mb-0 mt-0">
                      {{ element.newAnalysisUploadFullName }}
                    </h3>
                    <p class="text-muted mt-0">
                      {{ element.newAnalysisUploadEmail }}
                    </p>
                    <div>
                      <p class="text-primary text-small mt-2 mb-0">
                        Data da Ação:
                      </p>
                      <h4 class="text-muted mb-0 mt-0">
                        {{ element.changedDate }}
                      </h4>
                    </div>
                  </div>

                  <div *ngIf="element.justify">
                    <hr class="mt-2 mb-3" />

                    <p class="text-primary text-small mt-2 mb-0">
                      Justificativa:
                    </p>
                    <p class="text-muted mt-0">
                      {{ element.justify }}
                    </p>
                  </div>

                  <hr class="mt-2 mb-2" />

                  <div class="col-sm-12">
                    <div class="row">
                      <div *ngIf="element.currentStatus" class="mr-3">
                        <p class="text-primary text-small mb-0">Status:</p>
                        <p class="m-0">
                          <label
                            [ngClass]="{
                              vigente: element.currentStatus === 'VIGENTE',
                              inativo: element.currentStatus === 'INATIVO',
                              encerrado: element.currentStatus === 'ENCERRADO'
                            }"
                          >
                            {{ element.currentStatus | cap }}
                          </label>
                        </p>
                      </div>
                      <div *ngIf="element.currentStatus !== element.newStatus">
                        <p class="text-primary text-small mb-0">Novo Status:</p>
                        <p class="m-0">
                          <label
                            [ngClass]="{
                              vigente: element.newStatus === 'VIGENTE',
                              inativo: element.newStatus === 'INATIVO',
                              encerrado: element.newStatus === 'ENCERRADO'
                            }"
                          >
                            {{ element.newStatus | cap }}
                          </label>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-dark btn-sm" data-dismiss="modal">
          Fechar
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="excluir">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title mb-0" id="TituloModalCentralizado">
          Encerrar Insumo
        </p>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Fechar"
          #closeModalExcluir
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Deseja Realmente Encerrar o Insumo?</p>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn-sm btn btn-dark mr-2 btn-mat-icon"
          data-dismiss="modal"
        >
          <mat-icon class="">close</mat-icon>
          Cancelar
        </button>
        <button
          type="submit"
          class="btn-sm btn btn-success btn-mat-icon"
          (click)="encerrarInsumo()"
        >
          <mat-icon class="">done</mat-icon>
          Sim
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="frequency">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title mb-0" id="TituloModalCentralizado">
          Alterar a Periodicidade
        </p>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Fechar"
          #closeFrequencyModal
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="mt-4 pb-4">
          <div class="w-100 d-flex justify-content-center">
            <mat-icon class="text-muted" style="font-size: 30pt !important">
              loop
            </mat-icon>
          </div>
          <h3 class="text-center text-muted mt-4">
            Alterar Periodicidade do Insumo
            {{ insumos.id }}?
          </h3>
          <p class="text-center text-muted"></p>
        </div>
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Periodicidade:</mat-label>
          <mat-select [formControl]="frequency" required>
            <mat-option
              *ngFor="let frequencyField of frequencyOptions"
              [value]="frequencyField"
            >
              {{ frequencyField }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn-sm btn btn-dark mr-2 btn-mat-icon"
          data-dismiss="modal"
        >
          <mat-icon class="">close</mat-icon>
          Cancelar
        </button>
        <button
          type="button"
          class="btn-sm btn btn-primary btn-mat-icon"
          (click)="updateFrequency()"
          [disabled]="frequency.invalid"
        >
          <mat-icon class="">done</mat-icon>
          Atualizar
        </button>
      </div>
    </div>
  </div>
</div>

<form [formGroup]="formUpdate" (ngSubmit)="onSubmitUpdate()">
  <div class="modal fade" id="update">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <p class="modal-title mb-0" id="TituloModalCentralizado">
            Atualizar Responsavel
          </p>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Fechar"
            #closeModalAlterarResponsavel
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Email Responsável do upload do insumo: </mat-label>
            <input
              type="text"
              matInput
              autocomplete="new-responsavel"
              aria-label="Email do(a) Responsável"
              [matAutocomplete]="auto1"
              [formControl]="searchUserCtrl1"
              required
              autocomplete="off"
              (click)="resetField(1)"
            />
            <mat-autocomplete #auto1="matAutocomplete">
              <mat-option *ngIf="isLoading" class="is-loading"
                >Carregando...</mat-option
              >
              <ng-container *ngIf="!isLoading">
                <mat-option
                  *ngFor="let user of filteredUsers1"
                  [value]="user.email"
                  (click)="setEmailResponsibleUpload(user)"
                >
                  <span>{{ user.email }}</span>
                </mat-option>
              </ng-container>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Email Responsável pela análise do insumo: </mat-label>
            <input
              type="text"
              matInput
              autocomplete="new-responsavel"
              aria-label="Email do(a) Responsável"
              [matAutocomplete]="auto2"
              [formControl]="searchUserCtrl2"
              required
              autocomplete="off"
              (click)="resetField(2)"
            />
            <mat-autocomplete #auto2="matAutocomplete">
              <mat-option *ngIf="isLoading" class="is-loading"
                >Carregando...</mat-option
              >
              <ng-container *ngIf="!isLoading">
                <mat-option
                  *ngFor="let user of filteredUsers2"
                  [value]="user.email"
                  (click)="setEmailAnalysisUpload(user)"
                >
                  <span>{{ user.email }}</span>
                </mat-option>
              </ng-container>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Justificativa:</mat-label>
            <input
              matInput
              type="text"
              formControlName="justify"
              maxlength="300"
              required
            />
          </mat-form-field>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn-sm btn btn-dark mr-2 btn-mat-icon"
            data-dismiss="modal"
          >
            <mat-icon class="">close</mat-icon>
            Cancelar
          </button>
          <button
            type="submit"
            class="btn-sm btn btn-success btn-mat-icon"
            [disabled]="formUpdate.invalid"
          >
            <mat-icon class="">done</mat-icon>
            Atualizar
          </button>
        </div>
      </div>
    </div>
  </div>
</form>

<form [formGroup]="formDeadLine" (ngSubmit)="onSubmitDeadLine()">
  <div class="modal fade" id="dataLimite">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <p class="modal-title mb-0" id="TituloModalCentralizado">
            Atualizar Data Limite
          </p>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Fechar"
            #closeModalAlterarDeadLine
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Data Limite:</mat-label>
            <input
              matInput
              formControlName="deadline"
              [matDatepicker]="pickerDataLimite"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="pickerDataLimite"
            ></mat-datepicker-toggle>
            <mat-datepicker #pickerDataLimite></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="modal-body">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Justificativa:</mat-label>
            <input
              matInput
              type="text"
              formControlName="justify"
              maxlength="300"
              required
            />
          </mat-form-field>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn-sm btn btn-dark mr-2 btn-mat-icon"
            data-dismiss="modal"
          >
            <mat-icon class="">close</mat-icon>
            Cancelar
          </button>

          <button type="submit" class="btn-sm btn btn-success btn-mat-icon">
            <mat-icon class="">done</mat-icon>
            Atualizar
          </button>
        </div>
      </div>
    </div>
  </div>
</form>

<div class="modal fade" id="encerrar">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title mb-0" id="TituloModalCentralizado">
          Encerrar Insumos
        </p>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Fechar"
          #closeModalEncerrar
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Deseja Realmente Encerrar esses Insumos?</p>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn-sm btn btn-dark mr-2 btn-mat-icon"
          data-dismiss="modal"
        >
          <mat-icon class="">close</mat-icon>
          Cancelar
        </button>
        <button
          type="submit"
          class="btn-sm btn btn-success btn-mat-icon"
          (click)="encerrarInsumos()"
        >
          <mat-icon class="">done</mat-icon>
          Sim
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="encerradosnada">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title mb-0" id="TituloModalCentralizado">AVISO!!</p>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Fechar"
          #closeModalEncerradosAlert
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Nenhum Insumo Selecionado!</p>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn-sm btn btn-dark mr-2 btn-mat-icon"
          data-dismiss="modal"
        >
          <mat-icon class="">close</mat-icon>
          Fechar
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="version">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title mb-0" id="TituloModalCentralizado">
          Mudança de Versão de Anexo de Insumo
        </p>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Fechar"
          #closeModalVersion
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="formChangeVersion">
          <div class="modal-body">
            <mat-form-field appearance="fill" class="w-100" required>
              <mat-label> Versões Disponíveis: </mat-label>
              <mat-select formControlName="version">
                <mat-option value="None" disabled="true">
                  Selecione Alguma Versão...
                </mat-option>
                <mat-option
                  *ngFor="let version of insumoVersionList"
                  [value]="version"
                  >{{ version }}</mat-option
                >
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Justificativa:</mat-label>
              <input
                matInput
                type="text"
                formControlName="justificativa"
                maxlength="300"
                required
              />
            </mat-form-field>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn-sm btn btn-dark mr-2 btn-mat-icon"
          data-dismiss="modal"
        >
          <mat-icon class="">close</mat-icon>
          Fechar
        </button>
        <button type="submit" class="btn btn-success btn-sm button-flex">
          <mat-icon>task_alt</mat-icon>
          Enviar
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="export"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3
          class="modal-title m-0"
          id="exampleModalLongTitle"
          *ngIf="!isDownloadProcess"
        >
          Selecione o Tipo de Relatório
        </h3>

        <h3
          class="modal-title m-0"
          id="exampleModalLongTitle"
          *ngIf="isDownloadProcess"
        >
          Gerando Relatório...
        </h3>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          #exportModal
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div
        class="modal-body d-flex justify-content-center align-items-center"
        *ngIf="isDownloadProcess"
      >
        <div class="loading-shade">
          <mat-spinner></mat-spinner>
        </div>
      </div>

      <div class="modal-body" *ngIf="!isDownloadProcess">
        <div class="d-flex justify-content-center align-items-center">
          <button
            type="button"
            class="btn btn-rose btn-sm mr-2 mb-2"
            data-dismiss="modal"
          >
            <div class="button-flex">
              <mat-icon>download</mat-icon>
              Resumido
            </div>
          </button>
          <button
            type="button"
            class="btn btn-rose btn-sm mr-2 mb-2"
            data-dismiss="modal"
          >
            <div class="button-flex">
              <mat-icon>download</mat-icon>
              Detalhado
            </div>
          </button>
        </div>
      </div>
      <div class="modal-footer" *ngIf="!isDownloadProcess">
        <button type="button" class="btn btn-dark btn-sm" data-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<!-- <div class="modal fade" id="importarInsumos">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title mb-0" id="TituloModalCentralizado">
          Importar Insumos
        </p>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Fechar"
          #closeModalImportar
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="d-flex flex-column">
          <div class="mt-4 pb-4 pl-3 pr-3">
            <div class="form-group">
              <div class="input-group">
                <div class="custom-file">
                  <input
                    type="file"
                    #fileUploadInsumo
                    id="fileUploadInsumo"
                    name="fileUploadInsumo"
                    multiple="multiple"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    class="custom-file-input"
                    (click)="uploadImport()"
                  />
                  <label class="custom-file-label" for="anexarDocumento"
                    >Upload</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="pb-4 pl-3 pr-3">
          <h3><strong>Passos Da Importação</strong></h3>
          <p>
            1. Faça o download da planilha/lodelo de importação
            <a
              href="https://portaldecomissionamento.vivo.com.br/assets/arquivos-modelos/layout_importacao_insumo.xlsx"
              target="_blank"
              rel="noopener noreferrer"
              >Layout.xls</a
            >
          </p>
          <p>2. Preencha as colunas da planilha</p>
          <p>3. Converta para CSV</p>
          <p>4. Envie o arquivo na área no campo de Upload acima.</p>
        </div>

        <div class="pb-4 pl-3 pr-3">
          <h3><strong>Observe as colunas:</strong></h3>
          <h4><strong>DeadLine</strong></h4>
          <p>Preencha Com uma data no formato dd/mm/yyyy</p>
          <h4><strong>SLA</strong></h4>
          <p>Preencha Com uma data no formato dd/mm/yyyy</p>
          <h4><strong>status</strong></h4>
          <p>0 - VIGENTE("Vigente")</p>
          <p>1 - ENCERRADO("Encerrado")</p>
          <p>2 - INATIVO("Inativo")</p>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn-sm btn btn-dark mr-2 btn-mat-icon"
            data-dismiss="modal"
          >
            <mat-icon class="">close</mat-icon>
            Cancelar
          </button>
          <button
            type="button"
            data-dismiss="modal"
            class="btn btn-success btn-sm button-flex"
          >
            <mat-icon>task_alt</mat-icon>
            Confirmar
          </button>
        </div>
      </div>
    </div>
  </div>
</div> -->
