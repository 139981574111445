import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { SelectionModel } from "@angular/cdk/collections";
import { HttpClient, HttpEventType } from "@angular/common/http";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { of } from "rxjs";
import {
  catchError,
  debounceTime,
  filter,
  finalize,
  map,
  switchMap,
  tap,
} from "rxjs/operators";
import { TableUtil } from "src/app/_helpers/tableUtil";
import { User } from "src/app/_models";
import { AccountService } from "src/app/_services";
import { InsumoService } from "src/app/_services/insumos.service";
import { UploadService } from "src/app/_services/upload.service";
import { environment } from "src/environments/environment";
import * as uuid from "uuid";
import { CompetenceDatePipe } from "../../_pipes/competence-date.pipe";

@Component({
  selector: "app-visualizar",
  templateUrl: "./visualizar.component.html",
  styleUrls: ["./visualizar.component.css"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
  providers: [CompetenceDatePipe],
})
export class VisualizarComponent implements OnInit {
  private paginator: MatPaginator;
  selection = new SelectionModel<PeriodicElement>(true, []);

  submitted = false;
  fields: any[] = [];
  isChecked = true;

  anexoInsumo: FormGroup;
  formUpdate: FormGroup;
  formDeadLine: FormGroup;
  formGroup: FormGroup;
  formFiltro: FormGroup;
  formChangeVersion: FormGroup;
  formAddNumeroSd: FormGroup;

  actualInsumoId: any;
  insumoVersionList: string[] = [];
  tempInsumoId: any;
  start = false;
  user: User;
  userToken: string;
  insumo: any = {};

  searchUserCtrl1 = new FormControl();
  searchUserCtrl2 = new FormControl();
  searchUserCtrlFiltro = new FormControl();

  errorMsg: string;
  filteredUsers1: any;
  filteredUsers2: any;
  filteredUsersFilter: any;
  isLoading = false;
  actualInsumoLength: number = 0;

  @ViewChild("fileUpload", { static: false }) fileUpload: ElementRef;
  @ViewChild("fileUploadInsumo", { static: false })
  fileUploadInsumo: ElementRef;
  files = [];
  filesImport = [];
  attachments: any[] = [];
  anexoDownload: string = environment.apiUrl + "/download/anexo-insumo/";

  formData: MatTableDataSource<any>;

  constructor(
    private insumosService: InsumoService,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private http: HttpClient,
    private uploadService: UploadService,
    private competenceDatePipe: CompetenceDatePipe
  ) {
    this.user = this.accountService.userValue.user;
    this.userToken = this.accountService.userValue.token;
  }

  ngOnInit(): void {
    this.formChangeVersion = this.formBuilder.group({
      version: ["", Validators.required],
      justificativa: ["", Validators.required],
    });

    this.formAddNumeroSd = this.formBuilder.group({
      numeroSd: ["", Validators.required],
    });

    this.anexoInsumo = this.formBuilder.group({
      justify: ["", [Validators.minLength(3), Validators.maxLength(300)]],
    });

    this.formUpdate = this.formBuilder.group({
      responsibleUpload: ["", [Validators.required]],
      analysisUpload: ["", [Validators.required]],
      justify: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(300),
        ],
      ],
    });
    this.formDeadLine = this.formBuilder.group({
      deadline: ["", [Validators.required]],
      justify: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(300),
        ],
      ],
    });

    this.formFiltro = this.formBuilder.group({
      email: [""],
      segment: [""],
      insumoName: [""],
      userId: [""],
      status: [""],
      competence: [""],
      insumoId: [""],
    });

    this.searchUserCtrl1.valueChanges
      .pipe(
        filter((value) => value?.length > 3),
        debounceTime(500),
        tap(() => {
          this.errorMsg = "";
          this.filteredUsers1 = [];
          this.isLoading = true;
        }),
        switchMap((value) =>
          this.http
            .get(`${environment.apiUrl}/api/user/search?email=${value}`)
            .pipe(
              finalize(() => {
                this.isLoading = false;
              })
            )
        )
      )
      .subscribe((data) => {
        if (data == undefined) {
          this.errorMsg = data["Error"];
          this.filteredUsers1 = [];
        } else {
          this.errorMsg = "";
          this.filteredUsers1 = data;
        }

        console.log(this.filteredUsers1);
      });

    this.searchUserCtrl2.valueChanges
      .pipe(
        filter((value) => value?.length > 3),
        debounceTime(500),
        tap(() => {
          this.errorMsg = "";
          this.filteredUsers2 = [];
          this.isLoading = true;
        }),
        switchMap((value) =>
          this.http
            .get(`${environment.apiUrl}/api/user/search?email=${value}`)
            .pipe(
              finalize(() => {
                this.isLoading = false;
              })
            )
        )
      )
      .subscribe((data) => {
        if (data == undefined) {
          this.errorMsg = data["Error"];
          this.filteredUsers2 = [];
        } else {
          this.errorMsg = "";
          this.filteredUsers2 = data;
        }

        console.log(this.filteredUsers2);
      });

    this.searchUserCtrlFiltro.valueChanges
      .pipe(
        filter((value) => value?.length > 3),
        debounceTime(500),
        tap(() => {
          this.errorMsg = "";
          this.filteredUsersFilter = [];
          this.isLoading = true;
        }),
        switchMap((value) =>
          this.http
            .get(`${environment.apiUrl}/api/user/search?email=${value}`)
            .pipe(
              finalize(() => {
                this.isLoading = false;
              })
            )
        )
      )
      .subscribe((data) => {
        if (data == undefined) {
          this.errorMsg = data["Error"];
          this.filteredUsersFilter = [];
        } else {
          this.errorMsg = "";
          this.filteredUsersFilter = data;
        }

        console.log(this.filteredUsersFilter);
      });
  }

  columnsToDisplay = [
    "select",
    "name",
    "publico",
    "segment",
    "competence",
    "deadline",
    "version",
    "dataEntrega",
    "actions",
  ];

  dataSource = new MatTableDataSource<PeriodicElement>();

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  uploadAnexoInsumo(insumo: any) {
    this.insumo = insumo;
    this.actualInsumoLength = insumo.attachments.length;
  }

  selectInsumoVersion(insumo: any) {
    this.insumo = insumo;
    this.insumo["insumoAnexos"].forEach((element) => {
      this.insumoVersionList.push("v" + element.version);
    });

    return;
  }

  onReset() {
    this.formFiltro.reset();
    this.searchUserCtrlFiltro.reset();
    this.getAllInsumos();
  }

  editPermission() {
    if (this.user.profile == "ROLE_ADMIN") {
      return false;
    }

    return true;
  }

  isOperador() {
    if (this.user.userType === "OPERACAO_VIVO") {
      return false;
    } else if (this.user.userType === "OPERADOR_VIVO_JURIDICO") {
      return false;
    } else if (this.user.userType === "OPERADOR_VIVO_AUDITORIA") {
      return false;
    } else {
      return true;
    }
  }

  onSubmit(): void {
    let updateAnexosInsumoDto: any = {};

    if (this.actualInsumoLength > 0) {
      if (this.anexoInsumo.invalid) {
        return;
      }

      updateAnexosInsumoDto = {
        userId: this.user.id,
        anexos: this.attachments,
        justificativa: this.anexoInsumo.get("justify").value,
        insumoId: this.insumo.id,
      };
      this.insumosService
        .updateAnexos(updateAnexosInsumoDto)
        .subscribe(() => this.getAllInsumos());

      this.anexoInsumo.reset();
    } else {
      updateAnexosInsumoDto = {
        anexos: this.attachments,
        justificativa: "",
        insumoId: this.insumo.id,
      };

      this.insumosService
        .updateAnexos(updateAnexosInsumoDto)
        .subscribe(() => this.getAllInsumos());
    }
    this.attachments = [];
    this.files = [];
    this.closeConfirmDoc.nativeElement.click();
  }

  @ViewChild("closeModalAlterarResponsavel")
  closeModalAlterarResponsavel: ElementRef;

  @ViewChild("closeModalAlterarDeadLine")
  closeModalAlterarDeadLine: ElementRef;

  @ViewChild("closeModalExcluir") closeModalExcluir: ElementRef;

  @ViewChild("closeModalEncerrar") closeModalEncerrar: ElementRef;

  @ViewChild("closeConfirmDoc") closeConfirmDoc: ElementRef;
  @ViewChild("closeModal") closeModal: ElementRef;

  @ViewChild("encerrados") encerrados: ElementRef;
  @ViewChild("closeModalEncerradosAlert") closeEncerradosAlert: ElementRef;
  @ViewChild("closeModalImportar") closeModalImportar: ElementRef;
  @ViewChild("encerradosNada") encerradosNada: ElementRef;

  @ViewChild("closeModalVersion") closeModalVersion: ElementRef;

  get formUp(): { [key: string]: AbstractControl } {
    return this.formUpdate.controls;
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
  }

  ngAfterViewInit() {
    this.getAllInsumos();
  }

  exportTable() {
    TableUtil.exportTableToExcel("table-insumo");
  }

  getAllInsumos() {
    // this.insumosService
    //   .getInsumoPageable(
    //     0,
    //     100,
    //     "id",
    //     "des",
    //     "TODOS",
    //     this.user.id,
    //     "TODOS",
    //     "TODOS",
    //     "TODOS",
    //     "TODOS",
    //     0
    //   )
    //   .subscribe((data) => {
    //     let newDataSource: any[] = [];
    //     data["content"].map((item) => {
    //       newDataSource.push({
    //         ...item,
    //         competence: this.competenceDatePipe.transform(item.competence),
    //         dataEntrega:
    //           item.insumoAnexos.length > 0
    //             ? item.insumoAnexos[item.insumoAnexos.length - 1].creationDt
    //             : "",
    //       });
    //     });
    //     this.dataSource = new MatTableDataSource(newDataSource);
    //     this.formData = this.dataSource;
    //     this.setDataSourceAttributes();
    //     this.start = true;
    //   });
  }

  desativarInsumo(insumoId: number) {
    const insumoDto = {
      insumoId: +insumoId,
      userId: this.user.id,
    };
    this.insumosService.inativarInsumo(insumoDto).subscribe(() => {
      this.getAllInsumos();
    });
  }

  displayedColumnsInterno: string[] = [
    "idArquivo",
    "nomeArquivo",
    "Justificativa",
    "dataEHoraEnvio",
    "versao",
    "acoes",
  ];

  getInsumoById(id) {
    this.insumosService.getInsumoById(id).subscribe((data) => {
      this.insumo = data as unknown as any;
      this.actualInsumoLength = data.attachments.length;
    });
  }

  closeInsumo() {
    const closeInsumoDto = {
      insumoId: this.tempInsumoId,
      userId: this.user.id,
    };
    this.insumosService.closeInsumo(closeInsumoDto).subscribe((data) => {
      this.getAllInsumos();
      this.tempInsumoId = null;
      this.closeModalExcluir.nativeElement.click();
    });
  }

  addNumeroSd() {
    const formAddNumeroSd = this.formAddNumeroSd.value;
    console.log(this.actualInsumoId);

    const insumoAddNumeroSdDto = {
      insumoId: this.actualInsumoId,
      userId: this.user.id,
      numeroSd: formAddNumeroSd.numeroSd,
    };

    console.log(insumoAddNumeroSdDto);

    this.insumosService
      .insumoAddNumeroSd(insumoAddNumeroSdDto)
      .subscribe((data) => {
        this.closeModal.nativeElement.click();
        this.tempInsumoId = null;
        location.reload();
      });
  }

  encerrarInsumos() {
    let insumoAlert = false;
    if (this.selection.selected.length <= 0) {
      this.closeModalEncerrar.nativeElement.click();
      this.encerradosNada.nativeElement.click();
      return;
    }

    this.selection.selected.forEach((element, index) => {
      if (element["createdUser"].email == this.user.email) {
        this.insumosService.deleteInsumo(element.id).subscribe((data) => {
          if (index === this.selection.selected.length - 1) {
            this.getAllInsumos();
            this.selection.clear();
            this.closeModalEncerrar.nativeElement.click();
          }
        });
      } else {
        insumoAlert = true;
      }

      if (index === this.selection.selected.length - 1 && insumoAlert) {
        this.getAllInsumos();
        this.selection.clear();
        this.closeModalEncerrar.nativeElement.click();
        this.encerrados.nativeElement.click();
      }
    });
  }

  onSubmitUpdate(): void {
    this.submitted = true;

    if (this.formUpdate.invalid) {
      return;
    }

    let tempAlterarResponsavel: any = {
      insumosId: this.actualInsumoId,
      responsibleUser: this.user.email,
      responsibleUpload: this.formUpdate.get("responsibleUpload").value,
      analysisUpload: this.formUpdate.get("analysisUpload").value,
      justify: this.formUpdate.get("justify").value,
    };

    this.submitted = false;
    this.updatedInsumoResponsavel(tempAlterarResponsavel);
  }

  onSubmitDeadLine(): void {
    this.submitted = true;

    if (this.formDeadLine.invalid) {
      return;
    }

    let tempAlterarDeadLine: any = {
      insumosId: this.actualInsumoId,
      responsibleUser: this.user.email,
      deadline: this.formDeadLine.get("deadline").value,
      justify: this.formDeadLine.get("justify").value,
    };

    this.submitted = false;
    this.updatedInsumoDeadline(tempAlterarDeadLine);
  }

  setEmailResponsibleUpload(user) {
    this.formUpdate.get("responsibleUpload").setValue(user.email);
  }

  setEmailAnalysisUpload(user) {
    this.formUpdate.get("analysisUpload").setValue(user.email);
  }

  setEmailResponsavel(user) {
    this.formFiltro.get("email").setValue(user.email);
  }

  resetField(form) {
    if (form === 1) {
      this.formUpdate.get("responsibleUpload").reset();
      this.searchUserCtrl1.reset();
    } else if (form === 2) {
      this.formUpdate.get("analysisUpload").reset();
      this.searchUserCtrl2.reset();
    } else {
      this.formFiltro.get("analysisUpload").reset();
      this.searchUserCtrlFiltro.reset();
    }
  }

  updatedInsumoResponsavel(insumoResponsavelDto) {
    this.insumosService
      .updateResponsavel(insumoResponsavelDto)
      .subscribe(() => {
        this.closeModalAlterarResponsavel.nativeElement.click();
        this.actualInsumoId = null;
        this.getAllInsumos();
      });
  }

  updatedInsumoDeadline(insumoDeadLineDto) {
    this.insumosService.updateDeadLine(insumoDeadLineDto).subscribe(() => {
      this.closeModalAlterarDeadLine.nativeElement.click();
      this.actualInsumoId = null;
      this.getAllInsumos();
    });
  }

  expandedElement: PeriodicElement;

  //uploads

  somArray = (list: any[]) => {
    let total = 0;
    list.map((item) => (total += item.progress));

    return total / list.length;
  };

  removeAnexoFormPreUpload(file: any) {
    this.attachments = this.attachments.filter(
      (item) => item.originalFileName !== file.originalFileName
    );
    this.files = this.files.filter(
      (item) => item.data.name !== file.originalFileName
    );
  }

  removeFile(file: any) {
    this.attachments = this.attachments.filter(
      (item) => item.originalFileName !== file.originalFileName
    );
    this.files = this.files.filter(
      (item) => item.data.name !== file.originalFileName
    );
  }

  uploadFile(file) {
    const formData = new FormData();
    formData.append("file", file.data);
    file.inProgress = true;
    const fileBytes = file.data.size;

    if (fileBytes > 10000000) {
      this.uploadService.validationAws().subscribe((keys) => {
        const splitFileName = String(file.data.name).split(".");
        const mimeType = splitFileName[splitFileName.length - 1];
        const fileKey = uuid.v4();
        const fileName = String(file.data.name);

        this.uploadService
          .uploadFileInS3(file.data, fileKey, mimeType, keys)
          .on("httpUploadProgress", function (progress) {
            let progressPercentage = Math.round(
              (progress.loaded / progress.total) * 100
            );
            file.progress = progressPercentage;
          })
          .promise()
          .then(() => {
            this.uploadService
              .uploadInsumoAnexoS3(this.user.id, fileKey, fileName)
              .subscribe((body) => {
                this.attachments.push(body);
              });
          });
      });
    } else {
      this.uploadService
        .uploadAnexoInsumo(formData, this.user.id)
        .pipe(
          map((event) => {
            switch (event.type) {
              case HttpEventType.UploadProgress:
                file.progress = Math.round((event.loaded * 100) / event.total);
                break;
              case HttpEventType.Response:
                return event;
            }
          }),
          catchError(() => {
            file.inProgress = false;
            return of(`${file.data.name} upload failed.`);
          })
        )
        .subscribe((event: any) => {
          if (typeof event === "object") {
            this.attachments.push(event.body);
          }
        });
    }
  }

  private uploadFiles() {
    this.fileUpload.nativeElement.value = "";
    this.files.forEach((file) => {
      this.uploadFile(file);
    });
  }

  onUploadButtonClick() {
    this.files = [];
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.files.push({ data: file, inProgress: false, progress: 0 });
      }
      this.uploadFiles();
    };
    // fileUpload.click();
  }

  //uploads
  downloadFileNotification(fileId, insumoid) {
    let path = this.downloadFile(fileId);
    window.open(path);

    this.insumosService
      .downloadNotification(this.user.id, insumoid, fileId)
      .subscribe(() => {
        console.log("Send notification");
      });
  }

  downloadFile(fileId: any) {
    return this.anexoDownload + fileId + "?token=" + this.userToken;
  }

  downloadFileInsumoContent(data: any) {
    const insumoVersion: number = Number(data.version.replace("v", ""));

    const file = data.insumoAnexos.find(
      (insumo) => insumo.version === insumoVersion
    );

    let path = this.downloadFile(file.id);

    this.insumosService
      .downloadNotification(this.user.id, data.id, file.id)
      .subscribe(() => console.log("Send notification"));
    window.open(path);
  }

  // filter() {
  //   const filtro = this.formFiltro.value;

  //   if (filtro.insumoName === "" || filtro.insumoName === null) {
  //     filtro.insumoName = "TODOS";
  //   }

  //   if (filtro.userId === "" || filtro.userId === null) {
  //     filtro.userId = this.user.id;
  //   }

  //   if (filtro.email === "" || filtro.email === null) {
  //     filtro.email = "TODOS";
  //   }

  //   if (filtro.segment === "" || filtro.segment === null) {
  //     filtro.segment = "TODOS";
  //   }

  //   if (filtro.status === "" || filtro.status === null) {
  //     filtro.status = "TODOS";
  //   }

  //   if (filtro.competence === "" || filtro.competence === null) {
  //     filtro.competence = "TODOS";
  //   }
  //   if (filtro.insumoId === "" || filtro.insumoId === null) {
  //     filtro.insumoId = "0";
  //   }

  //   console.log(filtro);
  //   this.insumosService
  //     .getInsumoPageable(
  //       0,
  //       100,
  //       "",
  //       "des",
  //       filtro.insumoName,
  //       this.user.id,
  //       filtro.email,
  //       filtro.segment,
  //       filtro.status,
  //       filtro.competence,
  //       filtro.insumoId
  //     )
  //     .subscribe((data) => {
  //       let newDataSource: any[] = [];
  //       data["content"].map((item) => {
  //         newDataSource.push({
  //           ...item,
  //           competence: this.competenceDatePipe.transform(item.competence),
  //           dataEntrega:
  //             item.insumoAnexos.length > 0
  //               ? item.insumoAnexos[item.insumoAnexos.length - 1].creationDt
  //               : "",
  //         });
  //       });
  //       this.dataSource = new MatTableDataSource(newDataSource);
  //       this.formData = this.dataSource;
  //       this.setDataSourceAttributes();
  //       this.start = true;
  //     });
  // }

  changeVersion() {
    if (this.formChangeVersion.invalid) {
      console.log(this.formChangeVersion.errors);
      return;
    }

    const insumoVersionDto = {
      insumosId: this.actualInsumoId,
      userId: this.user.id,
      version: this.formChangeVersion.get("version").value,
      justificativa: this.formChangeVersion.get("justificativa").value,
    };

    this.insumosService.updateVersion(insumoVersionDto).subscribe(() => {
      this.closeModalVersion.nativeElement.click();
      this.formChangeVersion.reset();
      this.actualInsumoId = null;
      this.getAllInsumos();
    });
  }

  getInsumoWithVersion(insumoAnexos: any[], version: string) {
    return insumoAnexos.find((item) => item.version == version);
  }

  //table Content
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;

    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  checkboxLabel(row?: PeriodicElement): string {
    if (!row) {
      return `${this.isAllSelected() ? "deselect" : "select"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${
      row.id + 1
    }`;
  }

  exportTableResumido() {
    TableUtil.exportJsonToExcel(this.createJsonForXlsResumido());
  }

  createJsonForXlsResumido() {
    const content = [];
    let data = new Object();

    this.columnsToDisplayResumido.forEach((element) => {
      data[element] = [] as any[];
    });

    let clean = data;

    this.formData.filteredData.forEach((element: IDataSource) => {
      const dataEntrega =
        element["dataEntrega"] === ""
          ? ""
          : this.transform(element["dataEntrega"]);

      data["id"] = element.id;
      data["name"] = element.name;
      data["publico"] = element.publico;
      data["segment"] = element.segment;
      data["competence"] = element.competence;
      data["creationDt"] = this.transform(element.creationDt);
      data["deadline"] = this.transform(element.deadline);
      data["version"] = element.version;
      data["dataEntrega"] = dataEntrega;
      data["responsibleUploadEmail"] = element.responsibleUpload?.email;
      data["analysisUploadEmail"] = element.analysisUpload?.email;

      clean = { ...data };

      content.push(clean);

      Object.keys(data).forEach((key) => {
        data[key] = [];
      });
    });

    return content;
  }

  exportTableDetalhado() {
    TableUtil.exportJsonToExcel(this.createJsonForXlsDetalhado());
  }

  createJsonForXlsDetalhado() {
    const content = [];
    let data = new Object();

    this.columnsToDisplayDetalhado.forEach((element) => {
      data[element] = [] as any[];
    });

    let clean = data;

    this.formData.filteredData.forEach((element: IDataSource) => {
      const createdUser = element.createdUser;
      const responsibleUpload = element.responsibleUpload;
      const analysisUpload = element.analysisUpload;
      const dataEntrega =
        element["dataEntrega"] === ""
          ? ""
          : this.transform(element["dataEntrega"]);

      data["id"] = element.id;
      data["name"] = element.name;
      data["publico"] = element.publico;
      data["segment"] = element.segment;
      data["competence"] = element.competence;
      data["deadline"] = this.transform(element.deadline);
      data["creationDt"] = this.transform(element.creationDt);
      data["dataEntrega"] = dataEntrega;
      data["version"] = element.version;
      data["observations"] = element.observations;
      data["area"] = element.area;
      data["frequency"] = element.frequency;
      data["insumoType"] = element.insumoType;
      data["segment"] = element.segment;
      data["status"] = element.status;
      data["sla"] = element.sla;
      data["sla"] = element.sla;
      data["createdUserRole"] = createdUser.profile;
      data["createdUserEmail"] = createdUser.email;
      data["createdUserLastName"] = createdUser.lastName;
      data["createdUserUserType"] = createdUser.userType;
      data["createdUserFirstName"] = createdUser.firstName;
      data["responsibleUploadUserRole"] = responsibleUpload.profile;
      data["responsibleUploadUserEmail"] = responsibleUpload.email;
      data["responsibleUploadUserLastName"] = responsibleUpload.lastName;
      data["responsibleUploadUserUserType"] = responsibleUpload.userType;
      data["responsibleUploadUserFirstName"] = responsibleUpload.firstName;
      data["analysisUploadRole"] = analysisUpload.profile;
      data["analysisUploadEmail"] = analysisUpload.email;
      data["analysisUploadLastName"] = analysisUpload.lastName;
      data["analysisUploadUserType"] = analysisUpload.userType;
      data["analysisUploadFirstName"] = analysisUpload.firstName;

      clean = { ...data };

      content.push(clean);

      Object.keys(data).forEach((key) => {
        data[key] = [];
      });
    });

    return content;
  }

  transform(date: number): string {
    function formatZero(numero) {
      return numero <= 9 ? "0" + numero : numero;
    }

    let timeStamp_date = new Date(date);
    let year = timeStamp_date.getFullYear();
    let month = formatZero(timeStamp_date.getMonth() + 1);
    let day = formatZero(timeStamp_date.getDate());
    let hour = formatZero(timeStamp_date.getHours());
    let min = formatZero(timeStamp_date.getMinutes());

    return `${day}/${month}/${year} - ${hour}:${min}`;
  }

  columnsToDisplayResumido = [
    "id",
    "name",
    "publico",
    "segment",
    "competence",
    "creationDt",
    "deadline",
    "version",
    "dataEntrega",
    "createdUserEmail",
    "responsibleUploadEmail",
    "analysisUploadEmail",
  ];

  columnsToDisplayDetalhado = [
    "id",
    "name",
    "publico",
    "segment",
    "competence",
    "deadline",
    "version",
    "dataEntrega",
    "observations",
    "area",
    "creationDt",
    "frequency",
    "insumoType",
    "segment",
    "status",
    "sla",
    "createdUserRole",
    "createdUserEmail",
    "createdUserLastName",
    "createdUserUserType",
    "createdUserFirstName",
    "responsibleUploadUserRole",
    "responsibleUploadUserEmail",
    "responsibleUploadUserLastName",
    "responsibleUploadUserUserType",
    "responsibleUploadUserFirstName",
    "analysisUploadRole",
    "analysisUploadEmail",
    "analysisUploadLastName",
    "analysisUploadUserType",
    "analysisUploadFirstName",
  ];

  uploadFileForInsumoCsv(file) {
    const formData = new FormData();
    formData.append("file", file.data);
    file.inProgress = true;
    this.uploadService.uploadAnexoInsumoCsv(formData).pipe(
      map((event) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            file.progress = Math.round((event.loaded * 100) / event.total);
            break;
          case HttpEventType.Response:
            return event;
        }
      }),
      catchError(() => {
        file.inProgress = false;
        return of(`${file.data.name} upload failed.`);
      })
    );
  }

  private uploadFilesCsv() {
    this.fileUploadInsumo.nativeElement.value = "";
    this.filesImport.forEach((file) => {
      this.uploadFileForInsumoCsv(file);
    });
  }

  uploadImport() {
    this.filesImport = [];
    const fileUpload = this.fileUploadInsumo.nativeElement;
    fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.filesImport.push({ data: file, inProgress: false, progress: 0 });
      }
      this.uploadFilesCsv();
    };
  }

  onUpdateInsumo() {
    this.getAllInsumos();
    this.closeModalImportar.nativeElement.click();
  }
}

export interface PeriodicElement {
  id: string;
  name: string;
  publico: string;
  segment: string;
  version: string;
  area: string;
  frequency: string;
  competence: string;
  deadline: string;
  actions: string;
}

// table interno
export interface PeriodicElementInterno {
  idArquivo: string;
  nomeArquivo: string;
  dataEntrega: string;
  versao: string;
  acoes: string;
}

export interface IDataSource {
  id: number;
  creationDt: number;
  name: string;
  segment: string;
  publico: string;
  area: string;
  insumoType: string;
  observations: string;
  status: string;
  version: null;
  createdUser: AnalysisUpload;
  responsibleUpload: AnalysisUpload;
  analysisUpload: AnalysisUpload;
  frequency: string;
  competence: string;
  deadline: number;
  sendEmail: boolean;
  insumoAnexos: InsumoAnexo[];
  insumoHistoryList: InsumoHistoryList[];
  expired: boolean;
  sla: number;
}

export interface AnalysisUpload {
  id: number;
  userType: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  password: string;
  profile: string;
  empresa: string;
  area: string;
  cargo: null;
  areaNaTelefonica: null;
  token: null;
  requestedAccess: null;
  phone: null;
  unidadeOrganizacional: null;
  gestorImediato: null;
  matricula: null;
  autorizadoId: null;
  twoFactorCode: null;
  firstAccess: boolean;
  enabled: boolean;
  confirmed: boolean;
  photo: null;
  firstAccessDate: number;
  lastAccessDate: number;
  tempPassword: null;
  ferias: null;
  historyPasswordsUsed: null;
}

export interface InsumoAnexo {
  id: number;
  originalFileName: string;
  fileName: string;
  justify: string;
  version: number;
  creationDt: number;
  userUpload: AnalysisUpload;
}

export interface InsumoHistoryList {
  id: number;
  currentStatus: string;
  newStatus: string;
  actionEnum: string;
  statusChangedDate: number;
  actionUser: AnalysisUpload;
  justify: string;
}
